<template>
  <div class="listProfiles">
    <div class="d-flex gap-3 align-items-center mb-3">
      <h1>Лист профилей</h1>
      <button class="btn btn-primary" @click="fetchProfiles(1)">Получить профили</button>
      <button class="btn btn-secondary" @click="openProxyModal">Добавить временный прокси</button>
    </div>
    <div class="d-flex gap-3 align-items-center mb-3">
      <!-- Поля для поиска -->
      <input v-model="searchQuery" class="form-control" placeholder="Поиск по названию" @input="fetchProfiles(1)">
      <input v-model="searchTag" class="form-control" placeholder="Поиск по тегу" @input="fetchProfiles(1)">
    </div>

    <!-- Модальное окно для прокси -->
    <ProxyModal v-if="showProxyModal" @close="showProxyModal = false" @save="saveTempProxy"/>

    <!-- Список профилей -->
    <ul v-if="profiles.length">
      <Profile
          v-for="profile in profiles"
          :key="profile.uuid"
          :profile="profile"
          @run-appilka="runAppilka"
          @update-status="updateProfileStatus"
      />
    </ul>

    <!-- Пагинация -->
    <Pagination v-if="totalPages > 1"
                :current-page="currentPage"
                :total-pages="totalPages"
                @page-changed="fetchProfiles"/>

    <!-- Сообщение если профили не найдены -->
    <p v-if="!profiles.length && !loading">Профили не найдены.</p>
  </div>
</template>

<script>
import axios from 'axios';
import ProxyModal from './comp/ProxyModal.vue';
import Profile from './comp/UserProfile.vue';
import Pagination from './comp/ProfilesPagination.vue';

export default {
  name: 'UserProfiles',
  components: { ProxyModal, Profile, Pagination },
  data() {
    return {
      profiles: [],       // Все профили
      showProxyModal: false,
      showPopup: false,
      currentPage: 1,      // Текущая страница
      totalPages: 1,       // Общее количество страниц
      loading: false,      // Флаг загрузки
      searchQuery: '',     // Поле для поиска по названию
      searchTag: ''        // Поле для поиска по тегу
    };
  },
  methods: {
    // Метод для получения профилей
    async fetchProfiles(page = 1, pageSize = 20) {
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        const response = await axios.get('/api/profiles', {
          headers: { 'Authorization': `Bearer ${token}` },
          params: {
            page,
            pageSize,
            searchQuery: this.searchQuery, // Параметр поиска по названию
            searchTag: this.searchTag      // Параметр поиска по тегу
          }
        });

        const { data, totalPages, currentPage } = response.data;
        this.profiles = data.map(profile => ({
          ...profile,
          statuses: profile.statuses || [],  // Статусы профиля
          notifications: profile.notifications || [],  // Уведомления профиля
          accounts: profile.accounts || []  // Добавляем аккаунты профиля
        }));
        this.totalPages = totalPages;
        this.currentPage = currentPage;
      } catch (error) {
        console.error('Ошибка при получении профилей:', error);
      } finally {
        this.loading = false;
      }
    },

    // Метод для запуска аппеляции
    async runAppilka(uuid, appilkaNumber) {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        const tempProxyResponse = await axios.get('/api/proxies', { headers: { 'Authorization': `Bearer ${token}` } });
        const tempProxy = tempProxyResponse.data.proxy.data;

        await axios.post(`/api/profile-actions/${uuid}/update-proxy-and-start`, {
          proxy: tempProxy,
          accountId: this.selectedAccount,  // Передаем выбранный аккаунт
          appilkaNumber
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log(`Аппликация #${appilkaNumber} запущена для аккаунта ${this.selectedAccount}.`);
      } catch (error) {
        console.error(`Ошибка при запуске аппликации #${appilkaNumber}:`, error);
      }
    },

    // Метод для обновления статусов профиля
    async updateProfileStatus(uuid, selectedAccount) {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        const tempProxyResponse = await axios.get('/api/proxies', { headers: { 'Authorization': `Bearer ${token}` } });
        const tempProxy = tempProxyResponse.data.proxy.data;

        await axios.post(`/api/profile-actions/${uuid}/update-proxy-and-start`, {
          proxy: tempProxy,
          action: 'updateStatus',
          accountId: selectedAccount  // Передаем выбранный аккаунт для обновления статуса
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log('Статусы обновлены.');
        await this.fetchProfiles(); // Обновляем список профилей после обновления статусов
      } catch (error) {
        console.error('Ошибка при обновлении статусов:', error);
      }
    },

    // Метод для сохранения временного прокси
    async saveTempProxy(proxyData) {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        await axios.post('/api/proxies', proxyData, { headers: { 'Authorization': `Bearer ${token}` } });
        console.log('Временный прокси сохранен.');
      } catch (error) {
        console.error('Ошибка при сохранении прокси:', error);
      }
    },

    // Открытие модального окна для временного прокси
    openProxyModal() {
      this.showProxyModal = true;
    },

    // Открытие формы для аппеляции
    openAppilkaPopup() {
      this.showPopup = true;
    },

    // Обработка формы из попапа
    handleSubmit(formData) {
      console.log('Данные формы отправлены:', formData);
      this.showPopup = false;
    }
  },
  mounted() {
    this.fetchProfiles(); // Загружаем профили при монтировании компонента
  }
};
</script>

