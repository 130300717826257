import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/Login.vue';    // Компонент логина
import Register from '../components/Register.vue';  // Компонент регистрации
import UserProfiles from '../components/UserProfiles.vue'; // Компонент с профилями
import TemplateManager from '../components/TemplateManager.vue'; // Новый компонент для управления шаблонами

const routes = [
    { path: '/login', component: UserLogin },          // Маршрут для логина
    { path: '/register', component: Register },        // Маршрут для регистрации
    { path: '/profiles', component: UserProfiles },    // Маршрут для профилей
    { path: '/templates', component: TemplateManager }, // Маршрут для управления шаблонами
    { path: '/', redirect: '/login' }  // Перенаправление на login по умолчанию
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Глобальный навигационный guard для проверки токена
router.beforeEach((to, from, next) => {
    if (to.path === '/login' || to.path === '/register') {
        return next();
    }

    const token = localStorage.getItem('token');

    if (!token) {
        return next('/login');
    }

    try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime) {
            alert('Your session has expired. Please log in again.');
            localStorage.removeItem('token');
            return next('/login');
        }
    } catch (error) {
        return next('/login');
    }

    next();
});

export default router;
