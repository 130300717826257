<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h2 class="text-center">Register</h2>
        <form @submit.prevent="registerUser">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
                type="email"
                v-model="email"
                class="form-control"
                id="email"
                placeholder="Enter your email"
                required
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input
                type="password"
                v-model="password"
                class="form-control"
                id="password"
                placeholder="Enter your password"
                required
            />
          </div>
          <div class="mb-3">
            <label for="tag" class="form-label">Tag</label>
            <input
                type="text"
                v-model="tag"
                class="form-control"
                id="tag"
                placeholder="Enter your tag"
                required
            />
          </div>
          <button type="submit" class="btn btn-primary w-100">Register</button>
        </form>
        <p class="text-center mt-3">
          Already have an account? <router-link to="/login">Login here</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserRegister',  // Изменили имя компонента
  data() {
    return {
      email: '',
      password: '',
      tag: ''
    };
  },
  methods: {
    async registerUser() {
      try {
        await axios.post('/api/auth/register', {
          email: this.email,
          password: this.password,
          tag: this.tag
        });
        console.log('User registered successfully');
        this.$router.push('/login');
      } catch (error) {
        console.error('Error registering user:', error);
      }
    }
  }
};
</script>
