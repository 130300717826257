<template>
  <div class="modal-backdrop">
    <div class="modal">
      <h2>Добавить временный прокси</h2>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label for="proxyType">Тип прокси:</label>
          <select v-model="proxyData.type" class="form-control">
            <option value="socks5">Socks5</option>
            <option value="http">HTTP</option>
          </select>
        </div>
        <div class="form-group">
          <label for="host">Host:</label>
          <input type="text" v-model="proxyData.host" required class="form-control"/>
        </div>
        <div class="form-group">
          <label for="port">Port:</label>
          <input type="number" v-model="proxyData.port" required class="form-control"/>
        </div>
        <div class="form-group">
          <label for="login">Login:</label>
          <input type="text" v-model="proxyData.login" class="form-control" placeholder="Опционально"/>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" v-model="proxyData.password" class="form-control" placeholder="Опционально"/>
        </div>
        <button type="submit" class="btn btn-primary">Сохранить прокси</button>
        <button type="button" class="btn btn-secondary" @click="$emit('close')">Закрыть</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProxyModal',
  data() {
    return {
      proxyData: {
        host: '',
        port: '',
        login: '',
        password: '',
        type: 'socks5'
      }
    };
  },
  methods: {
    submit() {
      this.$emit('save', this.proxyData);
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  display: block;
}
</style>
