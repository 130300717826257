<template>
  <li class="d-flex flex-row justify-content-between">
    <div class="left w-50">
      <div class="d-flex gap-2 align-items-center mb-2">
        <h3 class="m-0">{{ profile.title }}</h3>
        <!-- Проверяем, есть ли выбранный аккаунт, и отображаем его -->
        <div v-if="profile.selectedAccount" class="gray small">
          <small>{{ profile.selectedAccount }}</small>
        </div>
      </div>
      <div class="statues">
        <div class="status-ban">
          <strong>Статус бана:</strong>
          <div v-for="status in profile.statuses" :key="status.type" class="status-item">
            <span>{{ status.type }} - {{ status.message }}</span>
          </div>
        </div>
        <div class="status-notifications">
          <strong>Уведомления:</strong>
          <div v-if="profile.notifications && profile.notifications.length">
            <div v-for="notification in profile.notifications" :key="notification" class="notification-item">
              <span>{{ notification }}</span>
            </div>
          </div>
          <div v-else>
            <span>Нет уведомлений</span>
          </div>
        </div>
        <div class="status-appilki">
          <strong>Статус аппеляции:</strong>
          <div v-if="profile.latestAppeal">
            <span>{{ profile.latestAppeal }}</span> <!-- Здесь выводим текст апелляции -->
          </div>
          <div v-else>
            <span>Нет апелляций</span>
          </div>
        </div>

      </div>
    </div>
    <div class="right w-50 d-flex flex-column justify-content-between">
      <span class="small-text">
        <small><strong>Proxy:</strong> <strong>{{ profile.proxy ? profile.proxy.title || profile.proxy.host : 'нету прокси' }}</strong></small>
        <small><strong>UUID:</strong> <strong>{{ profile.uuid }}</strong></small>
        <small><strong>Tags:</strong> <strong>{{ profile.tags.join(', ') }}</strong></small>
        <small><strong>Last Active:</strong> <strong>{{ profile.last_active || 'Не запускался' }}</strong></small>
      </span>
      <div v-if="profile.accounts && profile.accounts.length" class="account-selection d-flex flex-column gap-1 align-items-end">
        <label for="account-select">Номер кабинета:</label>
        <select v-model="selectedAccount" id="account-select" class="form-select w-25">
          <option v-for="account in profile.accounts" :key="account" :value="account">{{ account }}</option>
        </select>
      </div>
      <div class="profile-actions ms-auto gap-2 d-flex flex-wrap">
        <button @click="openAppilkaPopup" class="btn btn-primary">Аппилка #1</button> <!-- Убрали uuid -->
        <button @click="runAppilka(profile.uuid, 2)" class="btn btn-secondary">Аппилка #2</button>
        <button @click="updateProfileStatus(profile.uuid)" class="btn btn-warning">Обновить статусы</button>
      </div>
    </div>

    <!-- Попап для формы аппеляции -->
    <PopupForm v-if="showAppilkaPopup" :formData="formData" :selectedAccount="selectedAccount" :selectedLang="selectedLang"
               @close="showAppilkaPopup = false"
               @submit="submitAppilkaForm"
               @reload="reloadData"
               @updateLang="updateLang" />
  </li>
</template>

<script>
import PopupForm from './PopupForm.vue'; // Подключаем компонент попапа
import axios from 'axios';

export default {
  components: { PopupForm },
  props: ['profile'],
  data() {
    return {
      selectedAccount: null,
      selectedLang: 'en', // По умолчанию язык английский
      showAppilkaPopup: false,
      formData: { // Оставляем только поля, которые заполняются динамически
        customerId: '',
        advertisingBusiness: '',
        whoPays: '',
        businessDescription: '',
        clientAgency: '',
        website: '',
        keywords: '',
        domainOwnership: '',
        issueSummary: '',
        askFeedback: true,
        receiveEmailAfterClose: true
      }
    };
  },
  mounted() {
    if (this.profile.selectedAccount) {
      this.selectedAccount = this.profile.selectedAccount;
    } else if (this.profile.accounts && this.profile.accounts.length) {
      this.selectedAccount = this.profile.accounts[0];
    }
  },
  methods: {
    async openAppilkaPopup() {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');

        // Загружаем данные при открытии попапа с учетом текущего языка
        const response = await axios.get('/api/templates/by-language', {
          headers: { 'Authorization': `Bearer ${token}` },
          params: { language: this.selectedLang }
        });

        const templates = response.data;

        const getRandomAnswer = (question) => {
          const matchingTemplate = templates.find((t) => t.question === question);
          if (matchingTemplate && matchingTemplate.answers) {
            const answersArray = matchingTemplate.answers.split('\n');
            return answersArray[Math.floor(Math.random() * answersArray.length)].trim();
          }
          return '';
        };

        // Подставляем ответы в поля формы
        this.formData = {
          ...this.formData,
          website: 'youtube.com',
          keywords: 'video',
          customerId: this.selectedAccount,
          whoPays: getRandomAnswer('Who pays for the Google Ads account(s)?'),
          businessDescription: getRandomAnswer('Please provide a brief description of your business'),
          clientAgency: getRandomAnswer('Information regarding client-agency relationship'),
          domainOwnership: getRandomAnswer('Domain ownership'),
          issueSummary: getRandomAnswer(this.getBanSummary()),
          askFeedback: true,
          receiveEmailAfterClose: true
        };

        this.showAppilkaPopup = true; // Открываем попап
      } catch (error) {
        console.error('Ошибка получения данных:', error);
      }
    }
    ,
    reloadData() {
      this.openAppilkaPopup(); // Обновляем данные через reload
    },
    updateLang(newLang) {
      this.selectedLang = newLang; // Обновляем язык в родительском компоненте
      this.reloadData(); // Загружаем данные на новом языке
    },
    geoCode() {
      const geoMatch = this.profile.title.match(/\((\w{2})\)/);
      return geoMatch ? geoMatch[1] : null;
    },

    getBanSummary() {
      if (this.profile.statuses.some(status => status.type === 'ОС')) {
        return 'бан ОС';
      } else if (this.profile.statuses.some(status => status.type === 'ПП')) {
        return 'бан ПП';
      } else {
        return 'бан общий';
      }
    },
    runAppilka(uuid, appilkaNumber) {
      this.$emit('run-appilka', uuid, appilkaNumber, this.selectedAccount);
    },
    updateProfileStatus(uuid) {
      this.$emit('update-status', uuid, this.selectedAccount);
    },
    submitAppilkaForm(formData) {
      if (this.isSubmitting) return;  // Проверяем, не отправляется ли форма уже
      this.isSubmitting = true;  // Устанавливаем флаг отправки

      const token = localStorage.getItem('token');
      const uuid = this.profile.uuid;

      // Добавляем geoCode в formData, используя Object.assign для сохранения всех данных
      const updatedFormData = Object.assign({}, formData, { geoCode: this.geoCode() });

      // Проверяем, что данные формы корректны
      console.log('Данные формы перед отправкой:', updatedFormData);

      axios.post(`/api/appeal/${uuid}/submit-appeal`, updatedFormData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            console.log('Апелляция отправлена:', response.data);
            this.showAppilkaPopup = false;
          })
          .catch(error => {
            console.error('Ошибка при отправке апелляции:', error);
          })
          .finally(() => {
            this.isSubmitting = false;  // Сбрасываем флаг после завершения
          });
    }



  }
};
</script>
