<template>
  <div id="app" class="container mt-5">
    <h1 class="mb-4">Управление шаблонами вопросов и ответов</h1>

    <!-- Добавление нового шаблона -->
    <div class="card p-3 mb-5">
      <h2 class="mb-3">Добавить новый шаблон</h2>
      <form @submit.prevent="addTemplate">
        <div class="form-group mb-3">
          <label>Язык (ISO):</label>
          <input type="text" class="form-control" v-model="newTemplate.language" required />
        </div>
        <div class="form-group mb-3">
          <label>Основной вопрос:</label>
          <input type="text" class="form-control" v-model="newTemplate.question" required />
        </div>
        <div class="form-group mb-3">
          <label>Ответы (по одному на строку):</label>
          <textarea class="form-control" v-model="newTemplate.answers" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Добавить шаблон</button>
      </form>
    </div>

    <!-- Список существующих шаблонов -->
    <h2 class="mb-4">Существующие шаблоны</h2>
    <div v-for="template in templates" :key="template._id" class="card p-3 mb-3">
      <p class="mb-2"><strong>{{ template.language }}</strong> - {{ template.question }}</p>
      <div>
        <button class="btn btn-warning me-2" @click="editTemplate(template)">Редактировать</button>
        <button class="btn btn-danger" @click="deleteTemplate(template._id)">Удалить</button>
      </div>
    </div>

    <!-- Модальное окно для редактирования -->
    <div v-if="editMode" class="modal fade show d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Редактировать шаблон</h5>
            <button type="button" class="btn-close" @click="editMode = false"></button>
          </div>
          <form @submit.prevent="updateTemplate">
            <div class="modal-body">
              <div class="form-group mb-3">
                <label>Язык (ISO):</label>
                <input type="text" class="form-control" v-model="editedTemplate.language" required />
              </div>
              <div class="form-group mb-3">
                <label>Основной вопрос:</label>
                <input type="text" class="form-control" v-model="editedTemplate.question" required />
              </div>
              <div class="form-group mb-3">
                <label>Ответы (по одному на строку):</label>
                <textarea class="form-control" v-model="editedTemplate.answers" required></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Сохранить изменения</button>
              <button type="button" class="btn btn-secondary" @click="editMode = false">Отмена</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const templates = ref([]);
    const newTemplate = ref({
      language: '',
      question: '',
      answers: ''
    });
    const token = localStorage.getItem('token'); // Получение токена для авторизации
    const editedTemplate = ref({
      _id: '',
      language: '',
      question: '',
      answers: ''
    });
    const editMode = ref(false);

    const fetchTemplates = async () => {
      const res = await fetch('http://localhost:3000/api/templates', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      templates.value = await res.json();
    };

    const addTemplate = async () => {
      await fetch('http://localhost:3000/api/templates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newTemplate.value)
      });
      fetchTemplates();
      newTemplate.value.language = '';
      newTemplate.value.question = '';
      newTemplate.value.answers = '';
    };

    const editTemplate = (template) => {
      editMode.value = true;
      // Проверяем, является ли answers массивом, если нет — разбиваем строку по новой строке
      editedTemplate.value = {
        ...template,
        answers: Array.isArray(template.answers) ? template.answers.join('\n') : template.answers
      };
    };

    const updateTemplate = async () => {
      await fetch(`http://localhost:3000/api/templates/${editedTemplate.value._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          language: editedTemplate.value.language,
          question: editedTemplate.value.question,
          answers: editedTemplate.value.answers.split('\n') // Преобразуем ответы в массив
        })
      });
      editMode.value = false;
      fetchTemplates();
    };

    const deleteTemplate = async (id) => {
      await fetch(`http://localhost:3000/api/templates/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchTemplates();
    };

    onMounted(() => {
      fetchTemplates();
    });

    return {
      templates,
      newTemplate,
      editedTemplate,
      editMode,
      addTemplate,
      editTemplate,
      updateTemplate,
      deleteTemplate
    };
  }
};
</script>

<style scoped>
.template-item {
  margin-bottom: 20px;
}

.modal.fade.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}
textarea {
  height: 300px;
}
</style>
